.ntrn {
  @include fluidCalc(
    height,
    $desktop_loader-menu-height-1,
    $desktop_loader-menu-height-2
  );
  @include respond-to-up($tweakpoint-nav-toggle) {
    &__menu {
      > li > ul {
        display: none;
      }
    }

    &__loaded {
      .ntrn__menu {
        > li > ul {
          display: flex;
        }
      }
    }
  }

  &__loader {
    position: absolute;
    top: calc(50% + 10px);
    left: calc(50% + 10px);

    > div {
      position: absolute;
      left: -20px;
      top: -20px;
      border: 2px solid $spinner_colour;
      border-bottom-color: transparent;
      border-top-color: transparent;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      animation: rotate 1s 0s linear infinite;

      &:last-child {
        display: inline-block;
        top: -16px;
        left: -16px;
        width: 12px;
        height: 12px;
        animation-duration: 0.5s;
        border-color: $spinner_colour #0000;
        animation-direction: reverse;
      }
    }

    .ntrn__loaded & {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
