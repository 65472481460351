.ntrn--flyout {
  @include inactive_panel();

  .ntrn__sub {
    @include inactive_panel();
    @include mobile_panel();
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0);
    width: 100%;
    height: calc(100vh - #{$mobile_sub-height});
    position: absolute;
    overflow: hidden;
    right: 0;

    &--active {
      transform: translate(0, 0);
    }
    &.ntrn--scroll {
      overflow-y: scroll;
    }
  }

  &.ntrn__sub--hidden:before,
  .ntrn__sub--hidden:before {
    opacity: 1;
    z-index: 1;
  }

  .ntrn__header {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $mobile-header-height;
    min-height: $mobile-header-height;
    padding-left: $mobile-header-padding-left;
    padding-right: $mobile-header-padding-right;
    top: 0;
    background: $mobile_header-bg;
    color: $mobile_header-color;
    z-index: 1;
    transition: $transition;
    z-index: z('heavenly');

    &--shadow {
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    }

    &--hide-arrow .ntrn__title:before {
      opacity: 0;
      visibility: hidden;
    }
  }

  .ntrn__title {
    position: relative;
    margin: 0;
    flex: 1 0 auto;
    height: 100%;
    text-align: center;
    height: 24px;
    line-height: 24px;
    font-family: $font-family-heading;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @include ms-icon($navigation-item-icon-backwards) {
      @extend %u-flex-row-xcenter-ycenter;
      @include absolute(0);
      height: 100%;
      opacity: 1;
      visibility: visible;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      height: 100%;
      animation-timing-function: cubic-bezier(0.25, 0.9, 0, 1);
      animation-fill-mode: forwards;
      animation-duration: 600ms;
    }

    &--forward {
      .ntrn__title-text--old {
        animation-name: panel-title-forward-out;
      }
      .ntrn__title-text--new {
        animation-name: panel-title-forward-in;
      }
    }

    &--back {
      .ntrn__title-text--old {
        animation-name: panel-title-backward-out;
      }
      .ntrn__title-text--new {
        animation-name: panel-title-backward-in;
      }
    }
  }
}

@keyframes panel-title-forward-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-40%);
  }
}

@keyframes panel-title-forward-in {
  from {
    opacity: 0;
    transform: translateX(60%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes panel-title-backward-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(60%);
  }
}

@keyframes panel-title-backward-in {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
