@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.ntrn__toggle > span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.nav-sections-item-title, .ntrn--flyout .ntrn__title:before {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.nav-sections-item-title, .ntrn__toggle {
  transition: background-color 0.225s cubic-bezier(0, 0, 0.2, 1);
  will-change: background-color;
  backface-visibility: hidden;
}

.sections.nav-sections, .action.showcart,
.field.search > .label {
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1), opacity 0.375s cubic-bezier(0, 0, 0.2, 1), visibility 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.sections.nav-sections {
  transform: translateX(-100%);
  visibility: hidden;
}

@media screen and (min-width: 992px) {
  .sections.nav-sections {
    transform: translateX(0%);
    visibility: visible;
  }
}

.action.showcart,
.field.search > .label {
  transform: translateY(0%);
  opacity: 1;
  transition-delay: 0.12s;
}

.action.showcart {
  transition-delay: 0s;
}

.ntrn__opened body {
  overflow: hidden;
  height: 100vh;
}

.ntrn__opened .action.showcart,
.ntrn__opened .field.search > .label {
  transform: translateY(-150%);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0;
}

.ntrn__opened .action.showcart {
  transition-delay: 0.12s;
}

.ntrn__opened .page-wrapper > *:not(.nav-sections):not(.page-header) {
  visibility: hidden;
}

.ntrn__opened .sections.nav-sections {
  transform: translateX(0);
  visibility: visible;
}

@media screen and (max-width: 1200px) {
  .ios-safari.ntrn__opened .page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

/**
 * Mobile Navigation sections/tabs
 */
.nav-sections {
  position: fixed;
  top: 44px;
  right: 0;
  width: 100%;
  height: calc(100vh - 44px);
  background: #000;
  display: none;
}

@media screen and (max-width: 991.98px) {
  .nav-sections {
    z-index: 1030;
  }
}

@media screen and (min-width: 992px) {
  .nav-sections {
    position: relative;
    top: -1px;
    right: 0;
    z-index: 5;
    height: auto;
    background: #000;
    display: block;
  }
}

.ntrn__loaded .nav-sections {
  display: block;
}

.nav-sections-items {
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (min-width: 992px) {
  .nav-sections-items {
    padding: 0 1rem;
  }
}

.nav-sections-item-title {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  width: 140px;
  height: 78px;
  color: #6c757d;
  background: #000;
  max-height: none;
}

@media screen and (min-width: 992px) {
  .nav-sections-item-title {
    display: none;
  }
}

.nav-sections-item-title:focus {
  outline: 0 none;
}

.nav-sections-item-title > a {
  color: #6c757d;
}

.nav-sections-item-title > a:hover {
  color: #6c757d;
  text-decoration: none;
}

.nav-sections-item-title > a:focus {
  outline: 0 none;
}

.nav-sections-item-title[aria-controls='store.links'] {
  left: 140px;
}

.nav-sections-item-title[aria-controls='store.links']:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls='store.links'] {
    width: 78px;
  }
}

@media screen and (min-width: 430px) {
  .nav-sections-item-title[aria-controls='store.links']:before {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls='store.links'] .nav-sections-item-switch {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
}

@media screen and (min-width: 992px) {
  .nav-sections-item-title[aria-controls='store.links'] {
    display: none;
  }
}

.nav-sections-item-title[aria-controls='store.settings'] {
  left: 280px;
}

.nav-sections-item-title[aria-controls='store.settings']:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls='store.settings'] {
    left: 218px;
    width: 78px;
  }
}

@media screen and (min-width: 430px) {
  .nav-sections-item-title[aria-controls='store.settings']:before {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls='store.settings'] .nav-sections-item-switch {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
}

@media screen and (min-width: 992px) {
  .nav-sections-item-title[aria-controls='store.settings'] {
    display: none;
  }
}

.nav-sections-item-title.active {
  background: #fff;
}

.nav-sections-item-title.active > a {
  color: #333333;
}

.nav-sections-item-content {
  padding-top: 78px;
}

@media screen and (max-width: 991.98px) {
  .nav-sections-item-content {
    height: calc(100vh - 44px);
  }
}

@media screen and (min-width: 992px) {
  .nav-sections-item-content {
    padding-top: 0;
    display: block !important;
  }
}

.nav-sections-item-content .switcher-language,
.nav-sections-item-content .navigation,
.nav-sections-item-content .header.links {
  background: #fff;
}

@media screen and (min-width: 992px) {
  #store\.links,
  #store\.settings {
    display: none !important;
  }
}

@media screen and (max-width: 991.98px) {
  .customer-welcome .customer-menu--account {
    -webkit-overflow-scrolling: touch;
  }
}

/**
 * Nav Hamburger Menu
 */
.ntrn__toggle {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0;
}

.ntrn__toggle:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .ntrn__toggle {
    display: none;
  }
}

.ntrn__toggle:before {
  text-align: center;
}

.ntrn__opened .ntrn__toggle {
  background: #000;
  overflow: hidden;
}

.ntrn__opened .ntrn__toggle:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 26px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.ntrn__opened .ntrn__toggle:before {
  animation: icon-swap-slide 0.375s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-fill-mode: both;
}

@keyframes icon-swap-slide {
  0%,
  20% {
    opacity: 0;
    transform: translateY(50%);
  }
  75%,
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes icon-swap-slide-x {
  0%,
  20% {
    opacity: 0;
    transform: translateX(-100%);
  }
  75%,
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes icon-swap-slide-x-r {
  0%,
  20% {
    opacity: 0;
    transform: translateX(100%);
  }
  75%,
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.nav-sections-item-content .header.links {
  display: block;
  height: 100%;
}

.nav-sections-item-content .header.links > li {
  border-bottom: 1px solid #eee;
}

.nav-sections-item-content .header.links > li:first-child {
  border-bottom: 0 none;
}

.nav-sections-item-content .header.links a {
  padding: 1.5rem;
  color: #000;
}

.nav-sections-item-content .header.links a:after {
  display: none;
}

.navigation > .ui-menu:focus, .navigation > .ui-menu:active {
  outline: 0 none;
}

/** Desktop variables
 * Includes fluid responsive variables, will interpolate between set values based in viewport size
 * -1:$tweakpoint-nav-toggle (desktop breakpoint)
 * -2:xxl (max breakpoint) *
*/
.ntrn__label {
  display: inline-block;
  vertical-align: middle;
  background: #f00 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 500;
  padding: 9px 12px;
  top: auto;
  bottom: 100%;
  color: #fff;
  margin-left: 10px;
  z-index: 1;
  user-select: none;
  pointer-events: none;
}

@media screen and (min-width: 992px) {
  .ntrn__label {
    position: absolute;
    left: 50%;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub .ntrn__label {
    position: relative;
    left: auto;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub .ntrn__label i {
    transform: rotate(60deg);
    left: auto;
    top: 0;
    bottom: auto;
    right: 100%;
  }
}

.ntrn__label i {
  border-color: #f00 transparent transparent;
  border-style: solid solid solid none;
  border-width: 15px 9px 11px 0;
  top: 0;
  right: 100%;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  transform: rotate(60deg);
  width: 9px;
  margin-left: -10px;
  z-index: -1;
  color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 992px) {
  .ntrn__label i {
    transform: rotate(15deg);
    bottom: -20px;
    left: 20px;
    right: auto;
    top: auto;
  }
}

[data-ntrn-custom] {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 991.98px) {
  .ntrn__custom-img {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__custom-img img {
    max-width: calc(22.32143vw - 21.42857px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__custom-img img {
    max-width: 300px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__custom-img {
    margin-left: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__custom-img {
    margin-left: 30px;
  }
}

.ntrn__sub {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 {
    display: flex;
    flex-wrap: wrap;
    background: white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    height: auto;
    box-shadow: 0px 10px 10px rgba(42, 42, 42, 0.1);
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-left: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-left: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-top: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-top: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-right: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-right: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-bottom: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-bottom: 30px;
  }
}

.ntrn__sub--level1.ntrn__sub--active {
  opacity: 1;
  visibility: visible;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item:first-child > .ntrn__link {
  margin: 0;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
  font-weight: normal;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    font-size: calc(0.66964vw + 6.35714px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    margin-top: calc(1.78571vw - 15.71429px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    margin-top: 10px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub {
    margin-top: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub {
    margin-top: 30px;
  }
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub:first-child {
  margin: 0;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.2em;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    font-size: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    font-size: 30px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    margin-bottom: calc(2.23214vw - 12.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991.98px) {
  .ntrn__item--width .ntrn__sub--level1 {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--width .ntrn__sub--level1 {
    left: auto;
  }
}

@media screen and (max-width: 991.98px) {
  .ntrn__item--full .ntrn__sub--level1 {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--full .ntrn__sub--level1 {
    width: 100% !important;
    left: 0 !important;
  }
}

.ntrn__sub--level2 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ntrn__sub--level2 .ntrn__link {
  font-weight: normal;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level2 .ntrn__link {
    font-size: calc(0.66964vw + 6.35714px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level2 .ntrn__link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level2 .ntrn__link {
    margin-top: calc(1.78571vw - 15.71429px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level2 .ntrn__link {
    margin-top: 10px;
  }
}

.ntrn__sub--level2 .ntrn__item:first-child .ntrn__link {
  margin: 0;
}

.ntrn__sub--level3 {
  margin: 0 0 0 20px;
}

.ntrn__sub--level3 .ntrn__link {
  font-size: 12px;
}

.ntrn--resized .ntrn__sub {
  transition: none !important;
}

.ntrn__cols {
  display: flex;
  justify-content: space-between;
  flex: 1 1 0;
}

@media screen and (min-width: 992px) {
  .ntrn__cols {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__cols {
    margin-left: calc(-2.23214vw + 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__cols {
    margin-left: -30px;
  }
}

.ntrn__col {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1 1 0;
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__col {
    padding-left: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__col {
    padding-left: 30px;
  }
}

.ntrn__col:last-child .ntrn__sub--level2 {
  margin-bottom: 0;
}

.ntrn--accordion .ntrn__item .ntrn__link:after {
  transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  transform: rotate(45deg);
}

.ntrn--accordion .ntrn__item--active > .ntrn__link:after {
  transform: rotate(-135deg);
}

.ntrn--accordion .ntrn__sub {
  box-shadow: inset 0px 11px 18px -10px rgba(38, 50, 56, 0.2), inset 0px -11px 18px -10px rgba(38, 50, 56, 0.2);
  background: rgba(236, 239, 241, 0.6);
}

.ntrn--flyout:before {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  will-change: opacity;
}

.ntrn--flyout .ntrn__sub {
  background: white;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  top: 0;
  z-index: 1031;
  overflow-x: hidden !important;
  flex-direction: column;
  will-change: transform;
  display: flex;
  flex-direction: column;
  transform: translate(100%, 0);
  width: 100%;
  height: calc(100vh - 194px);
  position: absolute;
  overflow: hidden;
  right: 0;
}

.ntrn--flyout .ntrn__sub:before {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  will-change: opacity;
}

@media screen and (min-width: 992px) {
  .ntrn--flyout .ntrn__sub {
    position: relative;
    transform: none;
    overflow: visible !important;
  }
}

.ntrn--flyout .ntrn__sub--active {
  transform: translate(0, 0);
}

.ntrn--flyout .ntrn__sub.ntrn--scroll {
  overflow-y: scroll;
}

.ntrn--flyout.ntrn__sub--hidden:before,
.ntrn--flyout .ntrn__sub--hidden:before {
  opacity: 1;
  z-index: 1;
}

.ntrn--flyout .ntrn__header {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  min-height: 72px;
  padding-left: 1.5rem;
  padding-right: 25px;
  top: 0;
  background: #767676;
  color: #fff;
  z-index: 1;
  transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  z-index: 1090;
}

.ntrn--flyout .ntrn__header--shadow {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.ntrn--flyout .ntrn__header--hide-arrow .ntrn__title:before {
  opacity: 0;
  visibility: hidden;
}

.ntrn--flyout .ntrn__title {
  position: relative;
  margin: 0;
  flex: 1 0 auto;
  height: 100%;
  text-align: center;
  height: 24px;
  line-height: 24px;
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.ntrn--flyout .ntrn__title:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
  position: absolute;
  top: 0;
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.ntrn--flyout .ntrn__title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  height: 100%;
  animation-timing-function: cubic-bezier(0.25, 0.9, 0, 1);
  animation-fill-mode: forwards;
  animation-duration: 600ms;
}

.ntrn--flyout .ntrn__title--forward .ntrn__title-text--old {
  animation-name: panel-title-forward-out;
}

.ntrn--flyout .ntrn__title--forward .ntrn__title-text--new {
  animation-name: panel-title-forward-in;
}

.ntrn--flyout .ntrn__title--back .ntrn__title-text--old {
  animation-name: panel-title-backward-out;
}

.ntrn--flyout .ntrn__title--back .ntrn__title-text--new {
  animation-name: panel-title-backward-in;
}

@keyframes panel-title-forward-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-40%);
  }
}

@keyframes panel-title-forward-in {
  from {
    opacity: 0;
    transform: translateX(60%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes panel-title-backward-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(60%);
  }
}

@keyframes panel-title-backward-in {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.ntrn__sub {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 {
    display: flex;
    flex-wrap: wrap;
    background: white;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    height: auto;
    box-shadow: 0px 10px 10px rgba(42, 42, 42, 0.1);
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-left: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-left: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-top: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-top: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-right: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-right: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__sub--level1 {
    padding-bottom: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__sub--level1 {
    padding-bottom: 30px;
  }
}

.ntrn__sub--level1.ntrn__sub--active {
  opacity: 1;
  visibility: visible;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item:first-child > .ntrn__link {
  margin: 0;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
  font-weight: normal;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    font-size: calc(0.66964vw + 6.35714px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    margin-top: calc(1.78571vw - 15.71429px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item > .ntrn__link {
    margin-top: 10px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub {
    margin-top: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub {
    margin-top: 30px;
  }
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub:first-child {
  margin: 0;
}

.ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.2em;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    font-size: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    font-size: 30px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    margin-bottom: calc(2.23214vw - 12.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level1 .ntrn__cols .ntrn__col > .ntrn__item.ntrn__item--hassub > .ntrn__link {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991.98px) {
  .ntrn__item--width .ntrn__sub--level1 {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--width .ntrn__sub--level1 {
    left: auto;
  }
}

@media screen and (max-width: 991.98px) {
  .ntrn__item--full .ntrn__sub--level1 {
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--full .ntrn__sub--level1 {
    width: 100% !important;
    left: 0 !important;
  }
}

.ntrn__sub--level2 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ntrn__sub--level2 .ntrn__link {
  font-weight: normal;
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level2 .ntrn__link {
    font-size: calc(0.66964vw + 6.35714px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level2 .ntrn__link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__sub--level2 .ntrn__link {
    margin-top: calc(1.78571vw - 15.71429px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__sub--level2 .ntrn__link {
    margin-top: 10px;
  }
}

.ntrn__sub--level2 .ntrn__item:first-child .ntrn__link {
  margin: 0;
}

.ntrn__sub--level3 {
  margin: 0 0 0 20px;
}

.ntrn__sub--level3 .ntrn__link {
  font-size: 12px;
}

.ntrn--resized .ntrn__sub {
  transition: none !important;
}

@media screen and (min-width: 992px) {
  .ntrn--fadein .ntrn__sub--level1 {
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  }
  .ntrn--fadeinup .ntrn__sub--level1 {
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
    transform: translateY(20px);
  }
  .ntrn--fadeindown .ntrn__sub--level1 {
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
    transform: translateY(-20px);
  }
  .ntrn--tilt .ntrn__sub--level1 {
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
    transform: perspective(4100px) rotateX(-40deg);
  }
  .ntrn--fadeinup .ntrn__sub--level1.ntrn__sub--active,
  .ntrn--fadeindown .ntrn__sub--level1.ntrn__sub--active {
    transform: translateY(0);
  }
  .ntrn--tilt .ntrn__sub--level1.ntrn__sub--active {
    transform: perspective(0px) rotateX(0deg);
  }
}

.ntrn {
  height: calc(100vh - 122px);
  background: white;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden !important;
  overflow-y: hidden;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .ntrn {
    background: none;
    position: relative;
    overflow: visible !important;
    transform: translate(0);
    display: block !important;
    max-width: none;
    margin: 0;
  }
  .ntrn > .ntrn__menu {
    max-width: 1310px;
    margin: 0 auto;
  }
}

.ntrn--active {
  display: flex;
}

.ntrn--scroll {
  overflow-y: scroll;
}

.ntrn--resized {
  transition: none !important;
}

.ntrn * {
  box-sizing: border-box;
}

.ntrn__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media screen and (min-width: 992px) {
  .ntrn__menu {
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: row;
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
    line-height: 1.6;
    height: 100%;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__menu > li {
    position: static;
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  }
  .ntrn__menu > li:hover, .ntrn__menu > li.ntrn__item--active {
    background: white;
  }
  .ntrn__menu > li:hover > a, .ntrn__menu > li.ntrn__item--active > a {
    text-decoration: none;
    color: black;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__menu > li > a {
    text-align: center;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    text-decoration: none;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__menu > li > a {
    font-size: calc(0.44643vw + 9.57143px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__menu > li > a {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__menu > li > a {
    padding-left: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__menu > li > a {
    padding-left: 30px;
  }
}

@media screen and (min-width: 992px) and (min-width: 992px) {
  .ntrn__menu > li > a {
    padding-right: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 992px) and (min-width: 1440px) {
  .ntrn__menu > li > a {
    padding-right: 30px;
  }
}

.ntrn--resized .ntrn__menu {
  transition: none !important;
  overflow: hidden !important;
}

.ntrn__item {
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 991.98px) {
  .ntrn__item:last-child > .ntrn__link {
    border: none;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--top {
    position: static;
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  }
  .ntrn__item--top:hover, .ntrn__item--top.ntrn__item--active {
    background: white;
  }
  .ntrn__item--top:hover > .ntrn__link, .ntrn__item--top.ntrn__item--active > .ntrn__link {
    text-decoration: none;
    color: black;
  }
}

.ntrn__item--top > .ntrn__link {
  position: relative;
}

@media screen and (min-width: 992px) {
  .ntrn__item--list {
    position: relative;
  }
}

.ntrn__item--hassub > .ntrn__link:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .ntrn__item--hassub > .ntrn__link:after {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--hassub.ntrn__item--top > .ntrn__link .ntrn__text:after {
    content: "";
    line-height: 1;
    speak: none;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    font-family: "ms-icons";
  }
  .ntrn__item--hassub.ntrn__item--top > .ntrn__link .ntrn__text:after {
    position: relative;
    display: inline-block;
    color: white;
    top: auto;
    right: auto;
    margin: -5px 0 0 10px;
    height: 5px;
    width: 5px;
    transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
  }
  .ntrn__item--hassub.ntrn__item--top > .ntrn__link:hover .ntrn__text:after {
    color: black;
  }
  .ntrn__item--hassub.ntrn__item--top.ntrn__item--active .ntrn__text:after {
    color: black;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink {
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink {
    margin-left: calc(2.23214vw - 2.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__item--parentlink {
    margin-left: 30px;
  }
}

.ntrn__item--parentlink > .ntrn__link {
  font-weight: 700;
  justify-content: flex-start;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.ntrn__item--parentlink > .ntrn__link:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.ntrn__item--parentlink > .ntrn__link:after {
  margin: 0 0 0 10px;
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link:hover, .ntrn__item--parentlink > .ntrn__link:focus {
    background-color: black;
    border-color: black;
    color: white;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link {
    border: 1px solid;
    padding: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link {
    font-size: calc(0.89286vw + 3.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__item--parentlink > .ntrn__link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-left: calc(2.23214vw - 12.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-left: 20px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-top: calc(2.23214vw - 12.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-top: 20px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-right: calc(2.23214vw - 12.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-right: 20px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-bottom: calc(2.23214vw - 12.14286px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn__item--parentlink > .ntrn__link {
    padding-bottom: 20px;
  }
}

.ntrn__link {
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 16px;
  padding: 1.5rem;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid 1px solid #eee;
  transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1);
}

.ntrn__link:hover {
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .ntrn__link {
    display: inline-flex;
    padding: 0;
    color: black;
    border: none;
  }
  .ntrn__link:hover {
    color: #4d4d4d;
  }
}

.ntrn__opened .ntrn {
  transform: translate(0, 0);
}

@media screen and (min-width: 992px) {
  .ntrn {
    height: calc(2.23214vw + 27.85714px);
  }
}

@media screen and (min-width: 1440px) {
  .ntrn {
    height: 60px;
  }
}

@media screen and (min-width: 992px) {
  .ntrn__menu > li > ul {
    display: none;
  }
  .ntrn__loaded .ntrn__menu > li > ul {
    display: flex;
  }
}

.ntrn__loader {
  position: absolute;
  top: calc(50% + 10px);
  left: calc(50% + 10px);
}

.ntrn__loader > div {
  position: absolute;
  left: -20px;
  top: -20px;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  animation: rotate 1s 0s linear infinite;
}

.ntrn__loader > div:last-child {
  display: inline-block;
  top: -16px;
  left: -16px;
  width: 12px;
  height: 12px;
  animation-duration: 0.5s;
  border-color: white #0000;
  animation-direction: reverse;
}

.ntrn__loaded .ntrn__loader {
  opacity: 0;
  visibility: hidden;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
