.ntrn__label {
  display: inline-block;
  vertical-align: middle;
  background: #f00 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 500;
  padding: 9px 12px;
  top: auto;
  bottom: 100%;
  color: #fff;
  margin-left: 10px;
  z-index: 1;
  user-select: none;
  pointer-events: none;

  @include respond-to-up($tweakpoint-nav-toggle) {
    position: absolute;
    left: 50%;
  }

  .ntrn__sub & {
    @include respond-to-up($tweakpoint-nav-toggle) {
      position: relative;
      left: auto;
    }

    i {
      @include respond-to-up($tweakpoint-nav-toggle) {
        transform: rotate(60deg);
        left: auto;
        top: 0;
        bottom: auto;
        right: 100%;
      }
    }
  }

  i {
    border-color: #f00 transparent transparent;
    border-style: solid solid solid none;
    border-width: 15px 9px 11px 0;
    top: 0;
    right: 100%;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    transform: rotate(60deg);
    width: 9px;
    margin-left: -10px;
    z-index: -1;
    color: rgba(0, 0, 0, 0);

    @include respond-to-up($tweakpoint-nav-toggle) {
      transform: rotate(15deg);
      bottom: -20px;
      left: 20px;
      right: auto;
      top: auto;
    }
  }
}
