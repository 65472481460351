@include respond-to-up($tweakpoint-nav-toggle) {
  .ntrn__sub--level1 {
    .ntrn--fadein & {
      transition: $transition;
    }
    .ntrn--fadeinup & {
      transition: $transition;
      transform: translateY(20px);
    }
    .ntrn--fadeindown & {
      transition: $transition;
      transform: translateY(-20px);
    }
    .ntrn--tilt & {
      transition: $transition;
      transform: perspective(4100px) rotateX(-40deg);
    }

    &.ntrn__sub--active {
      .ntrn--fadeinup &,
      .ntrn--fadeindown & {
        transform: translateY(0);
      }
      .ntrn--tilt & {
        transform: perspective(0px) rotateX(0deg);
      }
    }
  }
}
