.ntrn--accordion {
  .ntrn__item {
    .ntrn__link {
      &:after {
        transition: $transition;
        transform: rotate(45deg);
      }
    }
    &--active > .ntrn__link:after {
      transform: rotate(-135deg);
    }
  }

  .ntrn__sub {
    box-shadow: inset 0px 11px 18px -10px rgba(38, 50, 56, 0.2),
      inset 0px -11px 18px -10px rgba(38, 50, 56, 0.2);
    background: rgba(236, 239, 241, 0.6);
  }
}
