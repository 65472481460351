.ntrn {
  &__cols {
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;

    @include respond-to-up($tweakpoint-nav-toggle) {
      flex-wrap: wrap;
      margin-bottom: 0;

      @include fluidCalc(
        margin-left,
        -$desktop_panel-spacing-1,
        -$desktop_panel-spacing-2
      );
    }
  }
  &__col {
    @include list_reset;
    flex: 1 1 0;

    @include respond-to-up($tweakpoint-nav-toggle) {
      @include fluidCalc(
        padding-left,
        $desktop_panel-spacing-1,
        $desktop_panel-spacing-2
      );
    }

    &:last-child .ntrn__sub--level2 {
      margin-bottom: 0;
    }
  }
}
