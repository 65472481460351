.sections.nav-sections {
  @extend %u-transform-fade-transition;
  transform: translateX(-100%);
  visibility: hidden;

  @include respond-to-up($tweakpoint-nav-toggle) {
    transform: translateX(0%);
    visibility: visible;
  }
}

.action.showcart,
.field.search > .label {
  @extend %u-transform-fade-transition;
  transform: translateY(0%);
  opacity: 1;
  transition-delay: 0.12s;
}

.action.showcart {
  transition-delay: 0s;
}

.ntrn__opened {
  body {
    overflow: hidden;
    height: 100vh;
  }

  .action.showcart,
  .field.search > .label {
    transform: translateY(-150%);
    opacity: 0;
    visibility: hidden;
    transition-delay: 0;
  }

  .action.showcart {
    transition-delay: 0.12s;
  }

  .page-wrapper > *:not(.nav-sections):not(.page-header) {
    visibility: hidden;
  }

  .sections.nav-sections {
    transform: translateX(0);
    visibility: visible;
  }
}

.ios-safari.ntrn__opened .page-header {
  @include respond-to-down(xl) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
