//global
$transition: all 600ms cubic-bezier(0.25, 0.9, 0, 1) !default;

//mobile
$mobile_toggle: white !default;
$mobile_toggle-active: $black !default;
$mobile_panel-bg: white !default;
$mobile_panel-z: z('fixed') + 1 !default;
$mobile_link-colour: black !default;
$mobile_link-padding: 1.5rem !default;
$mobile_link-font-size: 16px !default;
$mobile_menu-border: true !default;
$mobile_menu-border-color: $navigation-item-border-mobile !default;
$mobile_arrow-colour: map_get($theme-colors, 'primary') !default;
$mobile_pagedim: lighten(black, 70%) !default;
$mobile-closesub-bg: white !default;
$mobile_header-bg: $navigation-item-selected-bg !default;
$mobile_header-color: $navigation-item-selected-color !default;
$mobile_header-height: 72px !default;
$mobile_header-padding-left: 1.5rem !default;
$mobile_header-padding-right: 25px !default;
$mobile_spinner-colour: white !default;
$mobile_panel-height: $header-content-min-height-sm + $nav-section-height;
$mobile_sub-height: $header-content-min-height-sm + $nav-section-height +
  $mobile_header-height;

/** Desktop variables
 * Includes fluid responsive variables, will interpolate between set values based in viewport size
 * -1:$tweakpoint-nav-toggle (desktop breakpoint)
 * -2:xxl (max breakpoint) *
*/
$desktop_loader-menu-height-1: 50px !default;
$desktop_loader-menu-height-2: 60px !default;
$desktop_menu-bg: none !default;
$desktop_link-top-bg-hover: white !default;
$desktop_link-top-colour: white !default;
$desktop_link-top-colour-hover: black !default;
$desktop_link-top-padding-1: 20px !default;
$desktop_link-top-padding-2: 30px !default;
$desktop_link-top-font-size-1: 14px !default;
$desktop_link-top-font-size-2: 16px !default;
$desktop_link-top-separator: false !default;
$desktop_link-top-separator-color: lighten(black, 70%) !default;
$desktop_link-colour: black !default;
$desktop_link-colour-hover: lighten(black, 30%) !default;
$desktop_arrow: true !default;
$desktop_arrow-colour: white !default;
$desktop_arrow-colour-hover: black !default;
$desktop_panel-bg: white !default;
$desktop_panel-full-width: false !default;
$desktop_panel-shadow: 0px 10px 10px rgba(42, 42, 42, 0.1) !default;
$desktop_panel-spacing-1: 20px !default;
$desktop_panel-spacing-2: 30px !default;
$desktop_panel-link-margin-1: 2px !default;
$desktop_panel-link-margin-2: 10px !default;
$desktop_panel-level2-margin-1: 20px !default;
$desktop_panel-level2-margin-2: 30px !default;
$desktop_panel-level2-title-font-size-1: 20px !default;
$desktop_panel-level2-title-font-size-2: 30px !default;
$desktop_panel-level2-title-margin-1: 10px !default;
$desktop_panel-level2-title-margin-2: 20px !default;
$desktop_panel-level2-font-size-1: 13px !default;
$desktop_panel-level2-font-size-2: 16px !default;
$desktop_panel-level3-margin: 0 0 0 20px !default;
$desktop_panel-level3-font-size: 12px !default;
$desktop_parentlink-font-size-1: 12px !default;
$desktop_parentlink-font-size-2: 16px !default;
$desktop_parentlink-padding-1: 10px !default;
$desktop_parentlink-padding-2: 20px !default;
$desktop_panel-customimg-width-1: 200px !default;
$desktop_panel-customimg-width-2: 300px !default;
$spinner_colour: white !default;
