.ntrn {
  height: calc(100vh - #{$mobile_panel-height});
  background: $mobile_panel-bg;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden !important;
  overflow-y: hidden;
  width: 100%;

  @include respond-to-up($tweakpoint-nav-toggle) {
    background: $desktop_menu-bg;
    position: relative;
    overflow: visible !important;
    transform: translate(0);
    display: block !important;

    @if $page-wrapper-contained == false {
      max-width: none;
      margin: 0;

      > .ntrn__menu {
        max-width: map_get($container-max-widths, xxl);
        margin: 0 auto;
      }
    } @else {
      max-width: map_get($container-max-widths, xxl);
      margin: 0 auto 15px;
    }
  }

  &--active {
    display: flex;
  }

  &--scroll {
    overflow-y: scroll;
  }

  &--resized {
    transition: none !important;
  }

  & * {
    box-sizing: border-box;
  }

  &__menu {
    @include list_reset();
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include respond-to-up($tweakpoint-nav-toggle) {
      position: relative;
      margin: 0;
      display: flex;
      flex-direction: row;
      transition: $transition;
      line-height: 1.6;
      height: 100%;
    }

    > li {
      @include respond-to-up($tweakpoint-nav-toggle) {
        position: static;
        transition: $transition;

        &:hover,
        &.ntrn__item--active {
          background: $desktop_link-top-bg-hover;

          > a {
            text-decoration: none;
            color: $desktop_link-top-colour-hover;
          }
        }
      }

      > a {
        @include respond-to-up($tweakpoint-nav-toggle) {
          text-align: center;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          height: 100%;
          color: $desktop_link-top-colour;
          text-decoration: none;

          @include fluidCalc(
            font-size,
            $desktop_link-top-font-size-1,
            $desktop_link-top-font-size-2
          );
          @include fluidCalc(
            padding-left,
            $desktop_link-top-padding-1,
            $desktop_link-top-padding-2
          );
          @include fluidCalc(
            padding-right,
            $desktop_link-top-padding-1,
            $desktop_link-top-padding-2
          );
        }
      }
    }

    .ntrn--resized & {
      transition: none !important;
      overflow: hidden !important;
    }
  }

  &__item {
    padding: 0;
    @include list_reset();

    &:last-child > .ntrn__link {
      @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        border: none;
      }
    }

    &--top {
      @include respond-to-up($tweakpoint-nav-toggle) {
        position: static;
        transition: $transition;

        &:hover,
        &.ntrn__item--active {
          background: $desktop_link-top-bg-hover;

          > .ntrn__link {
            text-decoration: none;
            color: $desktop_link-top-colour-hover;
          }
        }
      }

      > .ntrn__link {
        position: relative;

        @if $desktop_link-top-separator {
          &:before {
            @include respond-to-up($tweakpoint-nav-toggle) {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              background: $desktop_link-top-separator-color;
              width: 1px;
              height: 100%;
            }
          }
        }
      }
      &:first-child {
        > .ntrn__link:before {
          @include respond-to-up($tweakpoint-nav-toggle) {
            @if $desktop_link-top-separator {
              display: none;
            }
          }
        }
      }
    }

    &--list {
      @include respond-to-up($tweakpoint-nav-toggle) {
        position: relative;
      }
    }

    &--hassub {
      > .ntrn__link {
        @include ms-icon('chevron-right', 16px, after);

        &:after {
          @include respond-to-up($tweakpoint-nav-toggle) {
            display: none;
          }
        }
      }
      &.ntrn__item--top {
        @include respond-to-up($tweakpoint-nav-toggle) {
          > .ntrn__link {
            .ntrn__text {
              @if $desktop_arrow == true {
                @include ms-icon('chevron-down', 13px, after);

                &:after {
                  position: relative;
                  display: inline-block;
                  color: $desktop_arrow-colour;
                  top: auto;
                  right: auto;
                  margin: -5px 0 0 10px;
                  height: 5px;
                  width: 5px;
                  transition: $transition;
                }
              }
            }
            &:hover {
              .ntrn__text:after {
                color: $desktop_arrow-colour-hover;
              }
            }
          }
          &.ntrn__item--active {
            .ntrn__text:after {
              color: $desktop_arrow-colour-hover;
            }
          }
        }
      }
    }
    &--parentlink {
      @include respond-to-up($tweakpoint-nav-toggle) {
        display: flex;
        align-items: flex-end;
      }
      @include fluidCalc(
        margin-left,
        $desktop_panel-spacing-1,
        $desktop_panel-spacing-2
      );
      > .ntrn__link {
        @include ms-icon('chevron-right', 16px, after);
        font-weight: 700;
        justify-content: flex-start;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        &:after {
          margin: 0 0 0 10px;
        }
        &:hover,
        &:focus {
          @include respond-to-up($tweakpoint-nav-toggle) {
            background-color: $desktop_link-top-colour-hover;
            border-color: $desktop_link-top-colour-hover;
            color: $desktop_link-top-colour;
          }
        }
        @include respond-to-up($tweakpoint-nav-toggle) {
          border: 1px solid;
          padding: 1rem;
        }

        @include fluidCalc(
          font-size,
          $desktop_parentlink-font-size-1,
          $desktop_parentlink-font-size-2
        );
        @include fluidCalc(
          padding-left,
          $desktop_parentlink-padding-1,
          $desktop_parentlink-padding-2
        );
        @include fluidCalc(
          padding-top,
          $desktop_parentlink-padding-1,
          $desktop_parentlink-padding-2
        );
        @include fluidCalc(
          padding-right,
          $desktop_parentlink-padding-1,
          $desktop_parentlink-padding-2
        );
        @include fluidCalc(
          padding-bottom,
          $desktop_parentlink-padding-1,
          $desktop_parentlink-padding-2
        );
      }
    }
  }

  &__link {
    @include link();
  }

  .ntrn__opened & {
    transform: translate(0, 0);
  }
}
