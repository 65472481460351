.nav-sections-item-content .header.links {
  display: block;
  height: 100%;

  > li {
    border-bottom: $navigation-item-border-mobile;

    &:first-child {
      border-bottom: 0 none;
    }
  }

  a {
    padding: $navigation-item-spacing-mobile;
    color: $navigation-item-color;

    &:after {
      display: none;
    }
  }
}

.navigation > .ui-menu {
  &:focus,
  &:active {
    outline: 0 none;
  }
}
