.ntrn {
  &__sub {
    @include list_reset;

    // following classes only appears on desktop
    &--level1 {
      @include respond-to-up($tweakpoint-nav-toggle) {
        display: flex;
        flex-wrap: wrap;
        background: $desktop_panel-bg;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        height: auto;
        box-shadow: $desktop_panel-shadow;

        @include fluidCalc(
          padding-left,
          $desktop_panel-spacing-1,
          $desktop_panel-spacing-2
        );
        @include fluidCalc(
          padding-top,
          $desktop_panel-spacing-1,
          $desktop_panel-spacing-2
        );
        @include fluidCalc(
          padding-right,
          $desktop_panel-spacing-1,
          $desktop_panel-spacing-2
        );
        @include fluidCalc(
          padding-bottom,
          $desktop_panel-spacing-1,
          $desktop_panel-spacing-2
        );
      }
      &.ntrn__sub--active {
        opacity: 1;
        visibility: visible;
      }

      .ntrn__cols .ntrn__col {
        > .ntrn__item {
          &:last-child {
            border: none;
            margin: 0;
            padding: 0;
          }
          &:first-child {
            > .ntrn__link {
              margin: 0;
            }
          }

          > .ntrn__link {
            font-weight: normal;

            @include fluidCalc(
              font-size,
              $desktop_panel-level2-font-size-1,
              $desktop_panel-level2-font-size-2
            );

            @include fluidCalc(
              margin-top,
              $desktop_panel-link-margin-1,
              $desktop_panel-link-margin-2
            );
          }

          &.ntrn__item--hassub {
            @include fluidCalc(
              margin-top,
              $desktop_panel-level2-margin-1,
              $desktop_panel-level2-margin-2
            );

            &:first-child {
              margin: 0;
            }
            > .ntrn__link {
              text-transform: uppercase;
              line-height: 1;
              font-weight: 700;
              letter-spacing: 0.2em;

              @include fluidCalc(
                font-size,
                $desktop_panel-level2-title-font-size-1,
                $desktop_panel-level2-title-font-size-2
              );

              @include fluidCalc(
                margin-bottom,
                $desktop_panel-level2-title-margin-1,
                $desktop_panel-level2-title-margin-2
              );
            }
          }
        }
      }

      .ntrn__item--width & {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
          width: 100% !important;
        }

        @include respond-to-up($tweakpoint-nav-toggle) {
          left: auto;
        }
      }

      .ntrn__item--full & {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
          width: 100% !important;
        }

        @include respond-to-up($tweakpoint-nav-toggle) {
          width: 100% !important;
          left: 0 !important;
        }
      }
    }

    &--level2 {
      @include list_reset;

      .ntrn__link {
        font-weight: normal;

        @include fluidCalc(
          font-size,
          $desktop_panel-level2-font-size-1,
          $desktop_panel-level2-font-size-2
        );

        @include fluidCalc(
          margin-top,
          $desktop_panel-link-margin-1,
          $desktop_panel-link-margin-2
        );
      }
      .ntrn__item:first-child {
        .ntrn__link {
          margin: 0;
        }
      }
    }

    &--level3 {
      margin: $desktop_panel-level3-margin;

      .ntrn__link {
        font-size: $desktop_panel-level3-font-size;
      }
    }

    .ntrn--resized & {
      transition: none !important;
    }
  }
}

@if $desktop_panel-full-width {
  .nav-sections-items,
  .nav-sections-item-content,
  .ntrn,
  .ntrn__menu,
  .ntrn__menu > li {
    @include respond-to-up($tweakpoint-nav-toggle) {
      position: static !important;
    }
  }
  .ntrn {
    @include respond-to-up($tweakpoint-nav-toggle) {
      transform: none !important;
    }
  }
  .ntrn__sub--level1 {
    @include respond-to-up($tweakpoint-nav-toggle) {
      padding-left: $container-padding;
      padding-right: $container-padding;
    }
    @media screen and (min-width: map_get($container-max-widths, xxl)) {
      padding-left: calc(
        (
            100% - #{map_get($container-max-widths, xxl)} + #{$container-padding *
              2}
          ) / 2
      );
      padding-right: calc(
        (
            100% - #{map_get($container-max-widths, xxl)} + #{$container-padding *
              2}
          ) / 2
      );
    }
  }
}
