@mixin inactive_panel {
  &:before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: $transition;
    will-change: opacity;
  }
}
@mixin mobile_panel {
  background: $mobile_panel-bg;
  height: 100%;
  transform-style: preserve-3d;
  transition: $transition;
  top: 0;
  z-index: $mobile_panel-z;
  overflow-x: hidden !important;
  flex-direction: column;
  will-change: transform;

  @include respond-to-up($tweakpoint-nav-toggle) {
    position: relative;
    transform: none;
    overflow: visible !important;
  }
}

@mixin list_reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin mobile_border {
  @if $mobile_menu_border {
    border-width: 1px;
  } @else {
    border-width: 0;
  }
}

@mixin link {
  @include mobile_border();
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: $mobile_link-font-size;
  padding: $mobile_link-padding;
  color: $mobile_link-colour;
  text-decoration: none;
  border-bottom: 1px solid $mobile_menu-border-color;
  transition: $transition;

  &:hover {
    text-decoration: none;
  }
  @include respond-to-up($tweakpoint-nav-toggle) {
    display: inline-flex;
    padding: 0;
    color: $desktop_link-colour;
    border: none;

    &:hover {
      color: $desktop_link-colour-hover;
    }
  }
}

@mixin fluidCalc(
  $properties,
  $min-value,
  $max-value,
  $min-screen: map_get($breakpoints, $tweakpoint-nav-toggle),
  $max-screen: map_get($breakpoints, xxl)
) {
  & {
    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation(
          $min-screen,
          $min-value,
          $max-screen,
          $max-value
        );
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: '+';
  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }
  @return calc(#{$a * 100}vw #{$sign} #{$b});
}
