[data-ntrn-custom] {
  @include list_reset();
}

.ntrn {
  &__custom {
    &-img {
      @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        display: none;
      }
      img {
        @include fluidCalc(
          max-width,
          $desktop_panel-customimg-width-1,
          $desktop_panel-customimg-width-2
        );
      }
      @include fluidCalc(
        margin-left,
        $desktop_panel-spacing-1,
        $desktop_panel-spacing-2
      );
    }
  }
}
